exports.components = {
  "component---packages-themes-gatsby-theme-flexiblocks-src-pages-404-jsx": () => import("./../../../../packages/themes/gatsby-theme-flexiblocks/src/pages/404.jsx" /* webpackChunkName: "component---packages-themes-gatsby-theme-flexiblocks-src-pages-404-jsx" */),
  "component---src-pages-about-us-approach-index-jsx": () => import("./../../../src/pages/about-us/approach/index.jsx" /* webpackChunkName: "component---src-pages-about-us-approach-index-jsx" */),
  "component---src-pages-about-us-team-index-jsx": () => import("./../../../src/pages/about-us/team/index.jsx" /* webpackChunkName: "component---src-pages-about-us-team-index-jsx" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-platform-index-jsx": () => import("./../../../src/pages/platform/index.jsx" /* webpackChunkName: "component---src-pages-platform-index-jsx" */),
  "component---src-pages-privacy-policy-index-jsx": () => import("./../../../src/pages/privacy-policy/index.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-jsx" */),
  "component---src-pages-products-and-services-for-scientists-index-jsx": () => import("./../../../src/pages/products-and-services/for-scientists/index.jsx" /* webpackChunkName: "component---src-pages-products-and-services-for-scientists-index-jsx" */),
  "component---src-pages-products-and-services-free-services-index-jsx": () => import("./../../../src/pages/products-and-services/free-services/index.jsx" /* webpackChunkName: "component---src-pages-products-and-services-free-services-index-jsx" */),
  "component---src-pages-terms-use-index-jsx": () => import("./../../../src/pages/terms-use/index.jsx" /* webpackChunkName: "component---src-pages-terms-use-index-jsx" */)
}

